@font-face {
  font-family: "Finlandica";
  src: url("app/assets/fonts/Finlandica-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Finlandica";
  src: url("app/assets/fonts/Finlandica-Regular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Finlandica";
  src: url("app/assets/fonts/Finlandica-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Finlandica", sans-serif;
}

#root {
  min-height: 100vh;
  position: relative;
}

* {
  font-family: "Finlandica", sans-serif;
}

.blueSlimScrollbar::-webkit-scrollbar {
  width: 6px;
}
.blueSlimScrollbar::-webkit-scrollbar-track {
  background: #e3e3e3;
}
.blueSlimScrollbar::-webkit-scrollbar-thumb {
  background: #002561;
  border-radius: 20px;
}

.tableHeaderSeparator:after {
  right: 0;
  top: 10px;
  width: 2px;
  height: 24px;
  content: " ";
  position: absolute;
  background: #e3e3e3;
}

#see-all-activities-comp {
  gap: 5px;
  right: 30px;
  display: flex;
  color: #002561;
  position: absolute;
  align-items: center;
}

@media (max-width: 768px) {
  #see-all-activities-comp {
    top: 97px;
  }
}

a {
  color: #002561 !important;
  text-decoration: underline;
}

.no-margin {
  margin: 0;
}
.no-margin > * {
  margin: 0;
}
